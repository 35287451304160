import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Link from 'next/link';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';

const TOPIC_URL = `/topic`

const useStyles = makeStyles((theme) => ({
    tabTextcolor: {
        color: "#000",
        marginLeft: "-16px",
        opacity: '1',
        ['@media (max-width:768px)']: {
            marginLeft: "0"
        },
    },

    textDecorationNone: {
        textDecoration: 'none',
        color: '#000',
        fontWeight: '500',
        margin: theme.spacing(-0.2, 1, 0, 0),
        padding: "0,8px,0,8px",
    },
    moretab: {
        width: "72px",
        textAlign: 'center',
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    extra: {
        fontSize: '14px',
        marginBottom: "-3px"
    },
    indicator: {
        height: "0px",
        display: 'none'
    },
    root: {
        minHeight: '35px'
    }
}));

function NewSliderNav(props) {
    const router = useRouter();
    const matches = useMediaQuery('(min-width:768px)');

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [filterData, setFilterData] = React.useState([])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function tagLists() {
        if (!matches) {
            const updatedtags = filterData.slice(0, 3)
            return updatedtags;
        }
        else {
            return filterData;
        }
    }

    useEffect(() => {
        axios
            .get(`/api/api/v1/top-tags-lists/`)
            .then(({data}) => {
                    const res = data.results;
                    setFilterData(res);
                })
            .catch(err => console.log(err));
    }, []);

    function handleOpenKeywordPage(word) {
        window.location = `/topic/${word}`
    }

    return (
        <div className={classes.root}>
            {props.keywordsList !== null && (
                <Tabs
                    value={value}
                    onChange={handleChange}
                    className={classes.tabTextcolor}
                    classes={{ indicator: classes.indicator }}
                    variant={matches ? "scrollable" : null}
                    centered={matches ? false : true}
                >
                    {tagLists().map((data) => (
                        <Tab
                            label={data.name}
                            className={
                                router.pathname === `${TOPIC_URL}/${data.name}` ? `${classes.active}` : `${classes.tabTextcolor}`
                            }
                            style={{ padding: "0" }}
                            key={data.name}
                            onClick={() => handleOpenKeywordPage(data.name)}
                        />
                    ))}
                    <div className={classes.moretab}>
                        <Link href={`/following`} prefetch={false}>
                            <a className={classes.textDecorationNone}>
                                <Typography
                                    className={[classes.textDecorationNone, classes.extra].join(' ')}
                                >
                                    MORE
                                </Typography>
                            </a>
                        </Link>
                    </div>
                </Tabs>
            )}
        </div>
    );
}

export default NewSliderNav;